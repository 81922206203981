'use strict';

var app = angular.module('app');

app.component('importplan', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/importplan.html',
  controller: ['ApiService', 'FormatterService', 'Upload', 'PeriodService', function (ApiService, FormatterService, Upload, PeriodService) {
    var $ctrl = this;

    $ctrl.periods = [];
    $ctrl.errorFile = false;
    $ctrl.csvfile = false;
    $ctrl.form = {};

    $ctrl.title = 'Importa Pianificazioni';
    $ctrl.buttonTitle = 'Carica File';
    $ctrl.error = false;

    $ctrl.$onInit = function () {
      $ctrl.periods = PeriodService.getPeriods(moment().year())
    };

    $ctrl.closeAlert = function(){
      $ctrl.error = false;
    }

    $ctrl.cancel = function () {
      $ctrl.dismiss({$value: 'cancel'});
    };

    $ctrl.setLoading = function(){
      $ctrl.loading = true;
      $ctrl.buttonTitle = 'Import in corso...';    
    };

    $ctrl.setLoaded = function(){
      $ctrl.loading = false;
      $ctrl.buttonTitle = 'Importa Pianificazioni';    
    }

    $ctrl.loading = false;

    $ctrl.uploadFile = function(file) {

      $ctrl.setLoading();

      Upload.upload({
            url: '/api/plan/import/'+$ctrl.form.period,
            data: {file: file, 
              lat: $ctrl.form.lat, 
              lng: $ctrl.form.lng, 
              city: $ctrl.form.citta,
              address: $ctrl.form.indirizzo,
              cap: $ctrl.form.cap,
              radius: $ctrl.form.radius}
        }).then(function (resp) {
            $ctrl.setLoaded();
            $ctrl.close({$value: resp.data})
        }, function (resp) {
            $ctrl.setLoaded();       
            $ctrl.error = 'Si è verificato un errore nel processamento del file CSV';
        });
    }
  
  }]
});