'use strict';

var app = angular.module('app');

app.component('poilist', {
  // defines a two way binding in and out of the component
  bindings: {
  },
  // Pulls in out template
  templateUrl: '/views/poilist.html',
  controller: ['ApiService', '$uibModal', '$log', '$timeout', 'NgMap', 'Notification', 'FileSaver', 'Blob', function (ApiService, $uibModal, $log, $timeout, NgMap, Notification, FileSaver, Blob) {

    var ctrl = this;

    NgMap.getMap().then(function(map) {
      ctrl.map = map;

      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "/contextmenu.js";
      document.body.appendChild(s);


      $timeout(function(){
        //	create the ContextMenuOptions object
        var contextMenuOptions={};
        contextMenuOptions.classNames={menu:'context_menu', menuSeparator:'context_menu_separator'};

        //	create an array of ContextMenuItem objects
        var menuItems=[];
        menuItems.push({className:'context_menu_item', eventName:'zoom_in_click', label:'Zoom in'});
        menuItems.push({className:'context_menu_item', eventName:'zoom_out_click', label:'Zoom out'});
        //	a menuItem with no properties will be rendered as a separator
        menuItems.push({});
        menuItems.push({className:'context_menu_item', eventName:'center_map_click', label:'Centra mappa qui'});
        menuItems.push({className:'context_menu_item', eventName:'search_map_click', label:'Cerca qui intorno'});
        contextMenuOptions.menuItems=menuItems;

        //	create the ContextMenu object
        var contextMenu=new ContextMenu(map, contextMenuOptions);

        //	display the ContextMenu on a Map right click
        google.maps.event.addListener(map, 'rightclick', function(mouseEvent){
          contextMenu.show(mouseEvent.latLng);
        });

        //	listen for the ContextMenu 'menu_item_selected' event
        google.maps.event.addListener(contextMenu, 'menu_item_selected', function(latLng, eventName){
          //	latLng is the position of the ContextMenu
          //	eventName is the eventName defined for the clicked ContextMenuItem in the ContextMenuOptions
          switch(eventName){
            case 'zoom_in_click':
              map.setZoom(map.getZoom()+1);
              break;
            case 'zoom_out_click':
              map.setZoom(map.getZoom()-1);
              break;
            case 'center_map_click':
              map.panTo(latLng);
              break;
            case 'search_map_click':
              ctrl.searchNearby(latLng.lat(), latLng.lng(), 1000)
              break;
          }
        });
      }, 1000);
    })
    .catch(function(e){
      console.log(e);
      console.log('error ngmap');
    });

    this.error = false;
    this.gridApi = null;
    this.mapOptions = {
      center: {
         latitude: 41.89193,
         longitude: 12.51133
      },
      zoom: 8
    };

    this.view = 'list';

    this.selectedMarkerModel = {};

    this.selection = [];

    this.contextMenu = {
      coords: {

      },
      options: {
        visible: false
      }
    };

    this.changeView = function(viewType){
      this.view = viewType;

      if(this.view=='map'){
//        this.addPositions(this.selection);
        this.addPositions(ctrl.gridApi.selection.getSelectedRows())
      }
    };

    this.addPositions = function(inputArray){
      for(var i=0;i<inputArray.length; i++){
        inputArray[i].position = [inputArray[i].latitude, inputArray[i].longitude];
      }
    };

    this.showInfoWindow = function(e, markerModel){
      markerModel.position = [
        markerModel.latitude,
        markerModel.longitude
      ];

      ctrl.selectedMarkerModel = markerModel;
      ctrl.map.showInfoWindow("poi-iw", 'm'+markerModel.id);
    };

    this.deleteSelected = function(){
      if(confirm('Sei sicuro di voler eliminare l\'impianto?')){
        var ids = [];
        var selectedRows = this.gridApi.selection.getSelectedRows();
        for(var i=0; i<selectedRows.length; i++){
          ids.push(selectedRows[i]['id']);
        }

        ApiService.destroyPois(ids)
        .then(function(){
          getData();
        })
        .catch(function(error){
          console.log(error);
        });
      }
    };

    this.placePosition = [];
    this.addressChanged = function(){
      ctrl.place = this.getPlace();
      if(ctrl.place.geometry){
        ctrl.map.setCenter(ctrl.place.geometry.location);
        ctrl.placePosition = [ctrl.place.geometry.location.lat(), ctrl.place.geometry.location.lng()];
      }
    };

    var cellTemplate = '<div>'+
    '<div ng-click="grid.appScope.cellClicked(row,col)" class="ui-grid-cell-contents" title="TOOLTIP">{{COL_FIELD CUSTOM_FILTERS}}</div>'+
    '</div>';

    this.cellClicked = function(row, col){
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: 'poiview',
        resolve: {
          data: {
            item: row.entity
          }
        }
      });

    };

    this.selecting = false;

    this.gridOptions = {
      appScopeProvider: this,
      enableSorting: true,
      enableColumnMenu: false,
      enableFiltering: true,
      enableRowSelection: true,
      multiSelect: true,
      enableSelectAll: false,
      paginationPageSize: 40,
      minRowsToShow: 40,
      virtualizationThreshold: 40,
      paginationPageSizes: [40],
      columnDefs: [
        { name: 'concessionaria', enableColumnMenu: false, width: '10%', cellTemplate: cellTemplate },
        { name: 'cimasa', enableColumnMenu: false, width: '5%', cellTemplate: cellTemplate },
        { name: 'formato', enableColumnMenu: false, width: '5%', cellTemplate: cellTemplate },
        { name: 'indirizzo', enableFiltering: true, enableColumnMenu: false, width: '35%', cellTemplate: cellTemplate },
        { name: 'cap', enableFiltering: true, displayName: 'CAP', enableColumnMenu: false, width: '5%', cellTemplate: cellTemplate },
        { name: 'citta', displayName: 'Città', enableColumnMenu: false, width: '10%', cellTemplate: cellTemplate },
        { name: 'municipio', enableFiltering: true, enableColumnMenu: false, width: '10%', cellTemplate: cellTemplate},
        { name: 'valore_impianto', enableFiltering: true, displayName: 'Valore', enableColumnMenu: false, width: '5%', cellTemplate: cellTemplate},
        { name: ' ', width: '13%', enableColumnMenu: false, enableSorting:false, enableFiltering: false, cellTemplate: '<div style="padding: 2px; text-align: center;" ><button class="btn btn-xs btn-success" style="margin-right: 4px" ng-click="grid.appScope.editRow(row.entity)"><span class="glyphicon glyphicon-pencil"></span> Edit</button><button class="btn btn-xs btn-info" ng-click="grid.appScope.editPicture(row.entity)"><span class="glyphicon glyphicon-camera"></span> Immagini</button></div>'}
      ],
      onRegisterApi: function(gridApi) {
        ctrl.gridApi = gridApi;
        getData();
     },
      data: []
    };

    this.selectAll = function() {
      ctrl.selecting = true;

      $timeout(function(){
        if(ctrl.gridApi.selection.getSelectAllState()){
          ctrl.gridApi.selection.clearSelectedRows();
        } else {
          // Get visible rows
          
          //var visibleRows = ctrl.gridApi.core.getVisibleRows(ctrl.gridApi.grid);
      
          // Select only the visible rows
          for(var i=0; i<ctrl.gridApi.grid.rows.length; i++){
            if(ctrl.gridApi.grid.rows[i].visible){
              
              ctrl.gridApi.selection.selectRow(ctrl.gridApi.grid.rows[i].entity);
            }
          }
        }
      }, 100);

      $timeout(function(){
        ctrl.selecting = false;
      }, 2500);

    };

    /*this.selectAll = function(){
      ctrl.selecting = true;
      $timeout(function(){
        if(ctrl.gridApi.selection.getSelectAllState()){
          ctrl.gridApi.selection.clearSelectedRows();
        } else {
          ctrl.gridApi.selection.selectAllRows();
        }
      }, 100);

      $timeout(function(){
        ctrl.selecting = false;
      }, 2500);
    };*/

    this.loading = false;
    var getData = function(){
      ctrl.loading = true;
      ApiService.searchPoi({})
      .then(function(response){
        ctrl.selection = [];
        ctrl.gridOptions.data = response.data.pageItems;
        ctrl.loading = false;
      })
      .catch(function(error){
        console.log(error)
        ctrl.error = 'Errore nel caricamento dei dati';
        ctrl.loading = false;
      })
    };


    this.editRow = function(entity){
      this.openEditModal(entity);
    };

    this.editPicture = function(entity){
      this.openPictureModal(entity);
    };

    this.updateRow = function(data){
      var index = -1;
      for(var i=0; i<this.gridOptions.data.length; i++){
        if(this.gridOptions.data[i]['id']==data.id){
          index = i
          break;
        }
      }

      if(index>=0){
        this.gridOptions.data[index] = data;
      } else {
        this.gridOptions.data.push(data);
      }
    }

    this.deleteRow = function(data){
      var index = -1;
      for(var i=0; i<this.gridOptions.data.length; i++){
        if(this.gridOptions.data[i]['id']==data.id){
          index = i
          break;
        }
      }

      if(index>=0){
        this.gridOptions.data.splice(index, 1);
      }
    }

    this.openEditModal = function (entity) {
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: 'poidetail',
        resolve: {
          data: {
            item: entity
          }
        }
      });

      modalInstance.result.then(function (result) {
        if(result==false){
          ctrl.deleteRow(entity);
        } else {
          ctrl.updateRow(result);
        }
      }, function () {
        console.log('modal-component dismissed at: ' + new Date());
      });
    };

    this.openPictureModal = function (entity) {
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: 'imageEditor',
        resolve: {
          poi: entity
        }
      });

      /*modalInstance.result.then(function (result) {

      }, function () {
        console.log('modal-component dismissed at: ' + new Date());
      });*/
    };

    this.openPlanModal = function (entity) {
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: 'plan',
        resolve: {
          data: {
            pois: ctrl.gridApi.selection.getSelectedRows()
          }
        }
      });

      modalInstance.result.then(function (result) {
        Notification.info({
          title: 'Esito operazione',
          message: result.message
        });
      }, function () {
        console.log('modal-component dismissed at: ' + new Date());
      });
    };

    this.csvFilter = false;
    this.geoFilter = false;

    this.openFilterModal = function () {
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: 'filterpoi',
        resolve: {}
      });

      modalInstance.result.then(function (result) {
        ctrl.csvFilter = true;
        this.geoFilter = false;
        ctrl.gridOptions.data = result;
      }, function () {
        console.log('modal-component dismissed at: ' + new Date());
      });
    };

    this.resetFilter = function(){
      ctrl.csvFilter = false;
      ctrl.geoFilter = false;
      getData();
    }

    this.searchPoint = {};

    this.searchNearby = function(lat, lng, radius){
      this.searchPoint.position = [lat,lng]
      this.searchPoint.radius = radius;
      this.geoSearch();
    }

    this.searchBySearchResult = function(){
      this.searchNearby(this.place.geometry.location.lat(), this.place.geometry.location.lng(), 1000)
    }

    this.geoSearch = function(){
      ApiService.searchNearbyPoi({
        lat: ctrl.searchPoint.position[0],
        lng: ctrl.searchPoint.position[1],
        radius: ctrl.searchPoint.radius*1.00/1000.00
      })
      .then(function(response){
        ctrl.gridOptions.data = response.data.rows;
        ctrl.gridApi.grid.modifyRows(ctrl.gridOptions.data);
        ctrl.gridApi.selection.selectAllRows();

        ctrl.addPositions(ctrl.gridApi.selection.getSelectedRows());
        ctrl.geoFilter = true;
        ctrl.csvFilter = false;
      })
      .catch(function(e){
        console.log(e)
      });
    }

    this.radiusUpdated = function(event){
      if(ctrl.geoFilter){
        console.log(this.radius);
        if(this.radius!=ctrl.searchPoint.radius){
          ctrl.searchPoint.radius = this.radius;
          ctrl.geoSearch();
        }
      }
    };

    this.centerUpdated = function(event){
      if(ctrl.geoFilter){
        console.log(this.center);
        if(this.center.lat()!=ctrl.searchPoint.position[0] || this.center.lng()!=ctrl.searchPoint.position[1]){
          ctrl.searchPoint.position = [this.center.lat(), this.center.lng()]
          ctrl.geoSearch();
        }
      }
    };

    this.exportPoi1 = function(){
      var ids = [];

      var rows = ctrl.gridApi.selection.getSelectedRows();
      for(var i=0; i<rows.length; i++){
        ids.push(rows[i].id);
      }

      window.open(ApiService.exportPoiUrl(ids), '_blank');
    };

    this.exportPoi = function(){
      var ids = [];

      var rows = ctrl.gridApi.selection.getSelectedRows();
      for(var i=0; i<rows.length; i++){
        ids.push(rows[i].id);
      }

      ApiService.exportPoi(ids)
      .then(function(response){
        var blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        });
        FileSaver.saveAs(blob, 'impianti_export.xlsx')
      });
    };
  }]
});
